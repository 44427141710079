import { Stack, UnorderedList, ListItem, Text } from '@chakra-ui/react';
import * as React from 'react';

interface TierCardOptions {
  title: string;
  monthlyPrice?: string;
  yearlyPrice?: string;
  free?: boolean;
  elements: JSX.Element;
}

const personalOptions: Array<TierCardOptions> = [
  {
    title: 'Individual Starter',
    free: true,
    elements: (
      <Stack>
        <Text>Included in this plan:</Text>
        <UnorderedList paddingX="15px">
          <ListItem>
            <Text>1 User</Text>
          </ListItem>
          <ListItem>
            <Text>1 Projects</Text>
          </ListItem>
          <ListItem>
            <Text>2 QR Codes</Text>
          </ListItem>
          <ListItem>
            <Text>50 Scans / Month</Text>
          </ListItem>
        </UnorderedList>
      </Stack>
    ),
  },
  {
    title: 'Individual Professional',
    monthlyPrice: '$9.99',
    yearlyPrice: '$7.99',
    elements: (
      <Stack>
        <Text>Included in this plan:</Text>
        <UnorderedList paddingX="15px">
          <ListItem>
            <Text>1 User</Text>
          </ListItem>
          <ListItem>
            <Text>2 Projects</Text>
          </ListItem>
          <ListItem>
            <Text>25 QR Codes</Text>
          </ListItem>
          <ListItem>
            <Text>100 Scans / Month</Text>
            <Text>+ $0.01 / per extra</Text>
          </ListItem>
        </UnorderedList>
      </Stack>
    ),
  },
];

const enterpriseOptions = [
  {
    title: 'Enterprise Starter',
    monthlyPrice: '$19.99',
    yearlyPrice: '$16.99',
    elements: (
      <Stack>
        <Text>Included in this plan:</Text>
        <UnorderedList paddingX="15px">
          <ListItem>
            <Text>5 Users</Text>
          </ListItem>
          <ListItem>
            <Text>10 Projects</Text>
          </ListItem>
          <ListItem>
            <Text>100 QR Codes</Text>
          </ListItem>
          <ListItem>
            <Text>100,000 Scans / Month</Text>
            <Text>+ $0.01 / per extra</Text>
          </ListItem>
          <ListItem>
            <Text>Role Based Management</Text>
          </ListItem>
          <ListItem>
            <Text>API / SDK Access</Text>
          </ListItem>
          <ListItem>
            <Text>Advanced Reporting</Text>
          </ListItem>
          <ListItem>
            <Text>Data Export</Text>
          </ListItem>
        </UnorderedList>
      </Stack>
    ),
  },
  {
    title: 'Enterprise Intermediate',
    monthlyPrice: '$99.99',
    yearlyPrice: '$85.99',
    elements: (
      <Stack>
        <Text>Included in this plan:</Text>
        <UnorderedList paddingX="15px">
          <ListItem>
            <Text>10 Users</Text>
          </ListItem>
          <ListItem>
            <Text>Unlimited Projects</Text>
          </ListItem>
          <ListItem>
            <Text>1,000 QR Codes</Text>
          </ListItem>
          <ListItem>
            <Text>Unlimited Scans / Month</Text>
          </ListItem>
          <ListItem>
            <Text>Role Based Management</Text>
          </ListItem>
          <ListItem>
            <Text>API / SDK Access</Text>
          </ListItem>
          <ListItem>
            <Text>Advanced Reporting</Text>
          </ListItem>
          <ListItem>
            <Text>Data Export</Text>
          </ListItem>
        </UnorderedList>
      </Stack>
    ),
  },
  {
    title: 'Enterprise Professional',
    monthlyPrice: '$999.99',
    yearlyPrice: '$859.99',
    elements: (
      <Stack>
        <Text>Included in this plan:</Text>
        <UnorderedList paddingX="15px">
          <ListItem>
            <Text>50 Users</Text>
          </ListItem>
          <ListItem>
            <Text>Unlimited Projects</Text>
          </ListItem>
          <ListItem>
            <Text>10,000 QR Codes</Text>
          </ListItem>
          <ListItem>
            <Text>Unlimited Scans / Month</Text>
          </ListItem>
          <ListItem>
            <Text>Role Based Management</Text>
          </ListItem>
          <ListItem>
            <Text>API / SDK Access</Text>
          </ListItem>
          <ListItem>
            <Text>Advanced Reporting</Text>
          </ListItem>
          <ListItem>
            <Text>Data Export</Text>
          </ListItem>
        </UnorderedList>
      </Stack>
    ),
  },
];

export { personalOptions, enterpriseOptions };
