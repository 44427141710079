import {
  Stack,
  Heading,
  Flex,
  Divider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Wrap,
  Skeleton,
  Box,
} from '@chakra-ui/react';
import { Auth, API } from 'aws-amplify';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { PrimaryButton } from '../../buttons';
import {
  personalOptions,
  enterpriseOptions,
} from '../createAccount/price-plan/subPlanOptions';
import TierCard from '../createAccount/tierCard/tierCard';
import CurrentPlan from './currentUsage';

const SubscriptionPlan = (): JSX.Element => {
  const { currentAccount, loading } = useSelector(
    (state: any) => state.account
  );

  const { pricePlan } = currentAccount || { pricePlan: 'individual Starter' };

  const getPlan = (e: string): string => {
    switch (e) {
      case 'Enterprise Professional': {
        return 'enterpriseProfessional_monthly';
      }
      case 'Enterprise Intermediate': {
        return 'enterpriseIntermediate_monthly';
      }
      case 'Enterprise Starter': {
        return 'enterpriseStarter_monthly';
      }
      case 'Individual Professional': {
        return 'individualProfessional_monthly';
      }
      default: {
        return '-1';
      }
    }
  };

  const getStripeLink = async (unparsedPricePlan: string) => {
    const plan = getPlan(unparsedPricePlan);
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      const myInit = {
        body: {
          return_url: window.location.href,
          planName: plan,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await API.post(
        'openscreen',
        `billing/session/${currentAccount?.accountId}`,
        myInit
      );
      window.location.href = res.redirectUrl;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Skeleton
      w={{ base: '80%', md: '70vw' }}
      marginX="40px"
      isLoaded={!loading}
    >
      <Box minH="40vh" padding="20px" bgColor="#fff">
        <Stack>
          <Heading>Subscription Plan</Heading>
          <Divider height="15px" orientation="horizontal" />
          <Flex bgColor="3" w="100%">
            <Tabs width="100%">
              <TabList>
                <Tab>Personal Plans</Tab>
                <Tab>Enterprise Plans</Tab>
              </TabList>
              <TabPanels width="100%">
                <TabPanel width="100%">
                  <Flex
                    direction={['column', 'row']}
                    marginTop="10px"
                    justify="start"
                    spacing={2}
                  >
                    <CurrentPlan />
                    <Flex
                      justify="start"
                      align="center"
                      w="100%"
                      direction={['column', 'row']}
                    >
                      {personalOptions.map((value) => {
                        return (
                          <TierCard
                            free={value.free}
                            title={value.title}
                            monthlyPrice={value.monthlyPrice}
                            yearlyPrice={value.yearlyPrice}
                          >
                            <PrimaryButton
                              onClick={() => getStripeLink(value.title)}
                              disabled={value.title === pricePlan?.name}
                            >
                              {value.title === pricePlan?.name
                                ? 'Current Plan'
                                : 'Change Plan'}
                            </PrimaryButton>
                            {value.elements}
                          </TierCard>
                        );
                      })}
                    </Flex>
                  </Flex>
                </TabPanel>
                <TabPanel width="100%">
                  <Flex
                    marginTop="10px"
                    justify="start"
                    direction={['column', 'row']}
                  >
                    <CurrentPlan />
                    <Flex
                      justify="start"
                      align="center"
                      w="100%"
                      direction={['column', 'row']}
                    >
                      {enterpriseOptions.map((value) => {
                        return (
                          <TierCard
                            title={value.title}
                            monthlyPrice={value.monthlyPrice}
                            yearlyPrice={value.yearlyPrice}
                          >
                            <PrimaryButton
                              onClick={() => getStripeLink(value.title)}
                              disabled={value.title === pricePlan?.name}
                            >
                              {value.title === pricePlan?.name
                                ? 'Current Plan'
                                : 'Change Plan'}
                            </PrimaryButton>
                            {value.elements}
                          </TierCard>
                        );
                      })}
                    </Flex>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Stack>
      </Box>
    </Skeleton>
  );
};

export default SubscriptionPlan;
