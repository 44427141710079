import { Stack, Heading, Text, Flex, Box, Skeleton } from '@chakra-ui/react';
import * as React from 'react';
import { useSelector } from 'react-redux';

const CurrentPlan = (): JSX.Element => {
  const { currentAccount, loading } = useSelector(
    (state: any) => state.account
  );

  const { pricePlan, currentPeriod } = currentAccount || {
    pricePlan: {
      name: 'Individual Starter',
      projects: 1,
      qrCodes: 2,
    },
    currentPeriod: {
      projects: 0,
      qrCodes: 0,
      users: 1,
      scansUsed: 0,
      scansRemaining: 50,
    },
  };

  return (
    <Skeleton alignContent="center" isLoaded={!loading} paddingY="10px">
      <Stack maxW="20vw" minW="250px" justify="center">
        <Heading fontSize="18pt">My Current Plan</Heading>
        <Box>
          <Flex marginY="20px" justify="space-between">
            <Text w="100px">{pricePlan?.name}</Text>
            <Text>${pricePlan?.monthlyPrice / 100 || 0}</Text>
          </Flex>
        </Box>
        <Heading marginBottom="25px" fontSize="18pt">
          Plan Usage
        </Heading>
        <Flex justify="space-between">
          <Text>Users</Text>
          <Text>
            {currentPeriod?.users} / {pricePlan?.users}
          </Text>
        </Flex>
        <Flex justify="space-between">
          <Text>Projects</Text>
          <Text>
            {currentPeriod?.projects} /{' '}
            {pricePlan?.projects === -1 ? 'Unlimited' : pricePlan?.projects}
          </Text>
        </Flex>
        <Flex justify="space-between">
          <Text>QR Codes</Text>
          <Text>
            {currentPeriod?.qrCodes} / {pricePlan?.qrCodes}
          </Text>
        </Flex>
        <Flex justify="space-between">
          <Text>Monthly Scans</Text>
          <Text>
            {currentPeriod?.scansUsed} /{' '}
            {currentPeriod?.scansRemaining === -1
              ? 'Unlimited'
              : currentPeriod?.scansRemaining}
          </Text>
        </Flex>
      </Stack>
    </Skeleton>
  );
};

export default CurrentPlan;
