/* eslint-disable no-nested-ternary */
import { Stack, Heading, Flex, Text } from '@chakra-ui/react';
import * as React from 'react';
// import { SecondaryButton } from '../../../buttons';

interface TierCardsProps {
  title: string;
  yearlyPrice: string;
  monthlyPrice: string;
  free?: boolean;
  disabled?: boolean;
  children: JSX.Element[];
}

const TierCard = ({
  title,
  children,
  yearlyPrice,
  disabled,
  monthlyPrice,
  free,
}: TierCardsProps): JSX.Element => {
  const [yearly] = React.useState(0);

  return (
    <Stack
      disabled={disabled}
      _disabled={{
        textColor: '#cecece',
      }}
      borderRadius="10px"
      border="1px"
      maxW="225px"
      marginX="10px"
      borderColor="gray.200"
      padding="10px"
      margin="10px"
      minH={{ base: '50vh', xl: '400px' }}
    >
      <Flex align="start" justify="space-between">
        <Heading marginX="5px" size="md">
          {title}
        </Heading>
        {free ? (
          <Stack height="55px">
            <Text fontSize="11pt">FREE</Text>
          </Stack>
        ) : yearly === 1 ? (
          <Stack height="75px" align="start">
            <Text fontSize="11pt">{yearlyPrice}</Text>
            <Text fontSize="11pt">/month</Text>
          </Stack>
        ) : (
          <Stack height="55px" align="start">
            <Text fontSize="11pt">{monthlyPrice}</Text>
            <Text fontSize="11pt">/month</Text>
          </Stack>
        )}
      </Flex>

      {/* {!free ? (
        <RadioGroup
          _disabled={{ _hover: { cursor: 'not-allowed' } }}
          disabled={disabled}
          onChange={togglePrices}
          value={yearly}
        >
          <Stack fontSize="11pt" spacing={2} direction="row">
            <Radio
              disabled={disabled}
              fontSize="11pt"
              colorScheme="blue"
              value={0}
            >
              Monthly
            </Radio>
            <Radio
              disabled={disabled}
              fontSize="11pt"
              colorScheme="blue"
              value={1}
            >
              Annually
            </Radio>
          </Stack>
        </RadioGroup>
      ) : (
        <></>
      )} */}

      {/* <a href="https://www.app.openscreen.com/signup">
        <Stack>
          <SecondaryButton maxW="100%" disabled={disabled}>
            Pick this
          </SecondaryButton>
        </Stack>
      </a> */}
      {children[0]}
      {children[1]}
    </Stack>
  );
};

export default TierCard;
