import * as React from 'react';
import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import SEO from '../../../components/seo/seo';
import SubscriptionPlan from '../../../components/accountProfile/billing-and-payment';

const SubscriptionPlanPage = (): JSX.Element => {
  return (
    <Box minH="90.5vh" maxW="1600px">
      <SEO title="Subscription Plan" />
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{
          type: 'spring',
          mass: 0.35,
          stiffness: 75,
          duration: 0.3,
        }}
      >
        <SubscriptionPlan />
      </motion.div>
    </Box>
  );
};

export default SubscriptionPlanPage;
